/* Google Fonts */
@import url('https://fonts.googleapis.com/css2?family=Nova+Mono&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Oxygen:wght@300;400;700&display=swap');
/* Bulma */
@import "https://cdn.jsdelivr.net/npm/bulma@0.9.4/css/bulma.min.css";

:root {
  --vermelho: #d50000;
  --indigo: #241d60;
  --branco: #fffafa;
  --preto: #24292f;
  --boxDefault: #0366d64d 0px 0px 0px 3px;
}

body {
  font-family: 'Oxygen', sans-serif;
  /* height: calc(100vh - 8em); */
  /* height: max-content; */
  color: var(--branco);
  background-color: var(--indigo);
}

hr {
  margin: 5% 10%;
  border: 3px solid var(--vermelho);
  width: 80vw;
}

.carregando {
  width: 300px;
  height: 300px;
  display: flex;
  margin: 30% auto;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  overflow: hidden;
  position: relative;
  animation: text-color 1s ease-in-out infinite alternate;
}

.carregando::before,
.carregando::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 15px solid transparent;
  mix-blend-mode: overlay;
  animation: rotate var(--duration) var(--timing) infinite;
  pointer-events: none;
}

.carregando::before {
  border-left-color: var(--indigo);
  --duration: 1s;
  --timing: ease-in;
}

.carregando::after {
  border-right-color: var(--vermelho);
  --duration: 1.5s;
  --timing: ease-out;
}

@keyframes text-color {
  0% {
    color: rgba(255, 250, 250, 1);
  }

  50% {
    color: rgba(255, 250, 250, .5);
  }

  100% {
    color: rgba(255, 250, 250, .1);
  }
}

@keyframes rotate {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

h3 {
  text-align: center;
  font-size: 150%;
  margin-top: 10%;
  margin-bottom: 5%;
}

.navegacao {
  color: var(--branco);
  background-color: var(--vermelho);
  transition: all 2s;
}

.centraliza {
  margin: auto;
}

.lucas {
  margin-top: 40%;
}

.line-1 {
  font-family: 'Nova Mono', monospace;
  font-size: 200%;
  position: relative;
  width: 22em;
  margin: 0 auto;
  border-right: 2px solid rgba(213, 0, 0, .75);
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
}

/* Animation */
.anim-typewriter {
  animation: typewriter 2s steps(17) 1s 1 normal both,
    /* tempo e quantidade da animação */
    blinkTextCursor 1s steps(32) infinite normal;
}

@keyframes typewriter {
  from {
    width: 0;
  }

  to {
    width: 9.5em;
  }
}

@keyframes blinkTextCursor {
  from {
    border-right-color: rgba(213, 0, 0, .75);
  }

  to {
    border-right-color: transparent;
  }
}

.front {
  text-align: center;
  font-size: 200%;
  opacity: 0;
  animation: show-front 4s linear 3s 1 normal both;
}

@keyframes show-front {
  from {
    opacity: 0;
    color: var(--indigo);
  }

  to {
    opacity: 1;
    color: rgba(213, 0, 0, 0.75);
  }
}

.texto {
  width: 80vw;
  margin: auto;
  text-align: justify;
  animation: show-texto 2s linear 3s 1 normal both;
}

@keyframes show-texto {
  from {
    opacity: 0;
    color: var(--indigo);
  }

  to {
    opacity: 1;
    color: var(--branco);
  }
}

.container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.card {
  color: var(--branco);
  background-color: var(--preto);
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10px;
  border-radius: 10px;
  box-shadow: var(--boxDefault);
  transition: box-shadow 500ms;
}

.card:hover {
  box-shadow: var(--vermelho) 0px 0px 10px 5px;
}

.habilidade-card {
  opacity: 0.9;
  justify-content: space-around;
  width: 130px;
  height: 150px;
}

.projeto-card {
  width: 300px;
  margin: 10px;
  padding: 10px 5px;
  justify-content: space-between;
}

.projeto-card p:first-child {
  font-size: 25px;
  margin-bottom: 5px;
}

.tec {
  flex-grow: 0.5;
  margin: 10px 0;
  width: 100%;
}

.tec-item {
  border: 2px solid var(--vermelho);
  border-radius: 10px;
  padding: 2px 5px;
  height: 35px;
  margin: 2px 5px;
}

.social-card {
  justify-content: center;
  padding: 10px 5px;
  width: 250px;
}

.card p {
  text-align: center;
}

.github-icon {
  display: flex;
  align-items: center;
  ;
}

.github-icon span {
  margin: 0 10px;
}

.social-card img {
  width: 50%;
  border-radius: 50%;
}

.libutton {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 7px;
  text-align: center;
  outline: none;
  text-decoration: none;
  color: #ffffff;
  width: 200px;
  height: 32px;
  border-radius: 16px;
  background-color: #0A66C2;
  font-family: "SF Pro Text", Helvetica, sans-serif;
}

.formulario {
  width: 70%;
  height: 330px;
  margin: 10px auto;
  padding-top: 10px;
}

.formulario label {
  margin: 10px 2px;
  display: flex;
  justify-content: space-around;
}

label span {
  margin-right: 2px;
  width: 80px;
}

.formulario textarea, .formulario input {
  background-color: var(--indigo);
  color: var(--branco);
  resize: none;
  width: 130px;
}

.formulario textarea {
  height: 80px;
}

.formulario button {
  background-color: var(--vermelho);
  border: none;
  border-radius: 10px;
  box-shadow: var(--boxDefault);
  color: var(--branco);
  cursor: pointer;
  font-size: 20px;
  margin-top: 10px;
  padding: 5px 10px;
  transition: all 0.5s;
}

.formulario button:hover {
  background-color: var(--indigo);
  font-size: 22px;
}

.abaixo {
  margin-bottom: 50px;
}

.foot {
  background-color: var(--vermelho);
  height: 3.25rem;
  text-align: center;
  padding-top: 1%;
  position: relative;
  bottom: 0px;
  width: 100%;
}

.error {
  text-align: center;
}

.botoes {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
}

.botoes button {
  background-color: var(--preto);
  border-radius: 8px;
  border-style: none;
  box-shadow: var(--boxDefault);
  box-sizing: border-box;
  color: var(--branco);
  cursor: pointer;
  font-size: 20px;
  font-weight: 500;
  height: 4rem;
  margin: 5% 10px;
  padding: 0 1.6rem;
  transition: all .5s;
  touch-action: manipulation;
}

.botoes button:hover {
  box-shadow: var(--vermelho) 0px 0px 10px 5px;
}

/* Media queries */

@media screen and (min-width: 500px) {
  h3 {
    font-size: 200%;
  }

  .carregando {
    margin-top: 30%;
  }

  .lucas {
    margin-top: 10%;
  }

  .line-1 {
    font-size: 300%;
  }

  .front {
    font-size: 300%;
  }

  .abaixo {
    margin-bottom: 200px;
  }

  .formulario {
    font-size: 20px;
    height: 400px;
    width: 450px;
  }
  
  .formulario label {
    width: 90%;
  }

  .formulario textarea, .formulario input {
    width: 200px;
  }
  
  .formulario textarea {
    height: 130px;
  }

  .botoes {
    flex-direction: row;
  }
}

@media screen and (min-width: 1000px) {
  h3 {
    font-size: 300%;
    margin-top: 5%;
  }

  .carregando {
    margin: 20% auto;
  }

  .lucas {
    margin-top: 20%;
  }

  .line-1 {
    font-size: 400%;
  }

  .front {
    font-size: 400%;
  }

  .texto {
    width: 70vw;
  }

  .abaixo {
    margin-bottom: 200px;
  }
}

@media screen and (min-width: 1600px) {
  h3 {
    font-size: 400%;
  }

  .carregando {
    margin-top: 9%;
  }

  .lucas {
    margin-top: 10%;
  }

  .line-1 {
    font-size: 500%;
  }

  .front {
    font-size: 500%;
  }

  .texto {
    width: 50vw;
  }
}

@media (pointer: fine) {
  .habilidade-texto {
    display: none;
  }

  .habilidade-card:hover .habilidade-texto {
    display: inline;
  }
}
